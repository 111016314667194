import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SessionTimeout from '../containers/SessionTimeout'
import questionnaireMap from '../questionnaireMap/index.js'
import {
  removePerson,
  sendMetricsData,
  setConfiguration,
  setInitialState,
  setLocalization,
  sendStateData,
  setPersonDateOfBirth,
} from '../redux/actionCreators'
import { Children, Siblings } from '../steps/index.js'
import Person from '../steps/Person'
import Context from '../utils/context/Context'
import FamilyHelper from '../utils/FamilyHelper.js'

class Questionnaire extends Component {
  static contextType = Context

  componentDidUpdate(prevProps) {
    const { questionnaire, removePerson } = this.props
    const { persons } = questionnaire

    const {
      questionnaire: { persons: prevPersons = {} },
    } = prevProps

    for (const id in persons) {
      const person = persons[id]
      const prevPerson = prevPersons[id]

      if (prevPerson) {
        const prevMother = prevPerson.relationshipToProband === 'probandsMother'
        const prevFather = prevPerson.relationshipToProband === 'probandsFather'

        if (prevMother || prevFather) {
          if ((!prevPerson.adopted || prevPerson.adopted === 'N') && person.adopted === 'Y') {
            _.forEach(new FamilyHelper(questionnaire).getSiblings(id), (parentSibling) => {
              removePerson({ personId: parentSibling.person.id })
            })
          }
        }
      }
    }
  }

  render() {
    const { questionnaire, isLocalizationInstalled, configuration } = this.props

    const {
      authState: { isAuthenticated },
    } = this.context

    if (
      !(
        isAuthenticated &&
        Object.keys(questionnaire).length &&
        isLocalizationInstalled &&
        Object.keys(configuration).length
      )
    ) {
      return null
    }

    return (
      <SessionTimeout>
        {questionnaireMap({
          questionnaire: this.props.questionnaire,
          configuration: this.props.configuration,
          renderProbandFamilySteps: this.renderProbandFamilySteps,
          renderMotherSteps: this.renderMotherSteps,
          renderFatherSteps: this.renderFatherSteps,
          renderFolder: this.renderFolder,
        })}
      </SessionTimeout>
    )
  }

  async componentDidMount() {
    const {
      setAuthState,
      authState: { isAuthenticated },
      dateOfBirth,
    } = this.context

    if (!isAuthenticated) {
      await this.props.setInitialState({
        setAuthState,
        setLocalization: this.props.setLocalization,
        setConfiguration: this.props.setConfiguration,
      })

      await this.props.sendMetricsData()
    }

    const {
      questionnaire: { persons, probandId },
      setPersonDateOfBirth,
      sendStateData,
    } = this.props

    if (persons[probandId] && !persons[probandId].dateOfBirth) {
      const promises = []
      for (const dateType in dateOfBirth) {
        promises.push(
          setPersonDateOfBirth({
            id: probandId,
            dateType,
            value: dateOfBirth[dateType],
          }),
        )
      }
      promises.push(sendStateData())
      await Promise.all(promises)
    }
  }

  /**
   * Returns a formatted array of components that Navigation will render as a sidebar Folder
   * @param {String} id Folder component key
   * @param {String} label The label text that will show in the sidebar
   * @param {Array<ReactDOM>} components List of steps that will be in the folder
   * @returns {Array}
   **/
  renderFolder(id, label, components = null) {
    if (components) {
      return [[{ id, label, type: 'folder' }, ...components]]
    } else {
      return []
    }
  }

  motherResolver = (proband) => (shouldCreate, state) => {
    const familyHelper = new FamilyHelper(state)

    return familyHelper.getPerson(proband).resolveParent('F', shouldCreate, 'probandsMother').id
  }

  fatherResolver = (proband) => (shouldCreate, state) => {
    const familyHelper = new FamilyHelper(state)

    return familyHelper.getPerson(proband).resolveParent('M', shouldCreate, 'probandsFather').id
  }

  renderGrandparent = ({
    proband,
    navTitle,
    idLabel,
    parent,
    parentSex,
    grandparent,
    grandparentSex,
  }) => {
    return this.renderPersonStep(navTitle, idLabel, grandparentSex, (shouldCreate, state) => {
      const familyHelper = new FamilyHelper(state)

      return familyHelper
        .getPerson(proband)
        .resolveParent(parentSex, shouldCreate, parent)
        .resolveParent(grandparentSex, shouldCreate, grandparent).id
    })
  }

  renderProbandFamilySteps = ({ proband }) => {
    return [
      [
        {
          id: 'patientsFamilyStep',
          label: 'Your Family',
          type: 'folder',
        },
        this.renderPatientsChildrenStep(),
        this.renderPersonSiblingCountsStep('Siblings', 'proband'),
        this.renderPersonStep('Mother', 'mother', 'F', this.motherResolver(proband)),
        this.renderPersonStep('Father', 'father', 'M', this.fatherResolver(proband)),
      ],
    ]
  }

  renderMotherSteps = ({ questionnaire, persons, proband }) => {
    const mothersFamilyStep = [
      {
        id: 'mothersFamilyStep',
        label: "Your Mother's Family",
        type: 'folder',
      },
      this.renderPersonSiblingCountsStep(
        "Your Mother's Siblings",
        'mother',
        this.motherResolver(proband),
      ),
      this.renderGrandparent({
        proband,
        navTitle: "Your Mother's Mother",
        idLabel: 'mat-gmother',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandmother',
        grandparentSex: 'F',
      }),
      this.renderGrandparent({
        proband,
        navTitle: "Your Mother's Father",
        idLabel: 'mat-gfather',
        parent: 'probandsMother',
        parentSex: 'F',
        grandparent: 'probandsMaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]

    const isMotherAdopted =
      persons[this.motherResolver(proband)(false, questionnaire)]?.adopted ?? ''

    return isMotherAdopted !== 'Y' ? [mothersFamilyStep] : null
  }

  renderFatherSteps = ({ questionnaire, persons, proband }) => {
    const fathersFamilyStep = [
      {
        id: 'fathersFamilyStep',
        label: "Your Father's Family",
        type: 'folder',
      },
      this.renderPersonSiblingCountsStep(
        "Your Father's Siblings",
        'father',
        this.fatherResolver(proband),
      ),
      this.renderGrandparent({
        proband,
        navTitle: "Your Father's Mother",
        idLabel: 'pat-gmother',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandmother',
        grandparentSex: 'F',
      }),
      this.renderGrandparent({
        proband,
        navTitle: "Your Father's Father",
        idLabel: 'pat-gfather',
        parent: 'probandsFather',
        parentSex: 'M',
        grandparent: 'probandsPaternalGrandfather',
        grandparentSex: 'M',
      }),
    ]
    const isFatherAdopted =
      persons[this.fatherResolver(proband)(false, questionnaire)]?.adopted ?? ''

    return isFatherAdopted !== 'Y' ? [fathersFamilyStep] : null
  }

  renderFamilyStep(
    isSiblingCountsStep,
    title,
    idLabel,
    customRenderProps,
    iconClassName,
    resolvePersonId = null,
    removePerson = false,
  ) {
    let id

    if (isSiblingCountsStep) {
      id = 'person-' + idLabel + '-siblings'
    } else {
      id = 'person-' + idLabel
    }

    const renderProps = {
      key: id,
      navId: id,
      navTitle: title,
      icon: iconClassName,
      targetPerson: idLabel,
      removePerson,
    }

    if (resolvePersonId) {
      renderProps.resolvePersonId = resolvePersonId
    }

    if (customRenderProps && typeof customRenderProps === 'object') {
      _.assign(renderProps, customRenderProps)
    }

    if (isSiblingCountsStep) {
      return <Siblings {...renderProps} />
    } else {
      return <Person {...renderProps} />
    }
  }

  // resolvePersonId is not required
  renderPersonStep(title, idLabel, sex, resolvePersonId = undefined) {
    let renderProps

    if (sex) {
      renderProps = renderProps || {}
      renderProps.sex = sex
    }

    return this.renderFamilyStep(false, title, idLabel, renderProps, 'person', resolvePersonId)
  }

  // resolvePersonId is not required
  renderPersonSiblingCountsStep(
    title,
    idLabel,
    resolvePersonId = undefined,
    relationshipPrefix = undefined,
  ) {
    return this.renderFamilyStep(
      true,
      title,
      idLabel,
      null,
      'people',
      resolvePersonId,
      relationshipPrefix,
    )
  }

  renderPatientsChildrenStep() {
    const key = 'probands-children'

    return <Children key={key} navId={key} icon="people" navTitle="Children" />
  }
}

const mapStateToProps = ({ questionnaire, localization, configuration }) => ({
  questionnaire,
  isLocalizationInstalled: !!Object.keys(localization).length,
  configuration,
})

export default connect(mapStateToProps, {
  setInitialState,
  setLocalization,
  setConfiguration,
  removePerson,
  sendMetricsData,
  setPersonDateOfBirth,
  sendStateData,
})(Questionnaire)
